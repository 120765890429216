@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

@layer base {
  .clickable {
    @apply hover:opacity-70 cursor-pointer select-none;
  }
  .clickable:active {
    @apply opacity-30;
  }
  html {
    @apply text-font;

  }
  body {
    @apply p-0 m-0;
    -webkit-font-smoothing:  antialiased !important;
    -moz-osx-font-smoothing:  grayscale !important;
  } 

  .stretch-children {
    @apply flex space-x-1;
  }

  .stretch-children > * {
    @apply flex-1;
  }

}

@layer components {
  select {
    background-image: url("data:image/svg+xml,%3Csvg width=%2712%27 height=%276%27 viewBox=%270 0 12 6%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M0.984375 0.984375H11.0156L6 6L0.984375 0.984375Z%27 fill=%27%23495057%27/%3E%3C/svg%3E");
    background-size: 14px;
    background-position: right 0.8rem center;
  }

  button {
    @apply hover:shadow-none !important;
    @apply hover:opacity-100 !important;
  }


  .hyphen{
    word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
}

/* Context menu on right click on form files */
.szh-menu {
  padding: 0px !important;
}

.szh-menu__item {
  padding: 0.5rem 1.5rem;
}

.szh-menu__item--hover {
  background-color: #E8FBFF;
}